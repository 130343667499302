import React from "react"
import styled from "styled-components"

import { hex2rgba } from "../../../styles/tools"

import { useTranslateValue } from "../../../data/TranslateContext"

const Title = styled.strong`
  display: block;
  margin: 0 0 0.675rem;
  padding: 0 0 0.75rem;

  border-bottom: 2px solid ${props => props.theme.color.primary};

  text-transform: uppercase;
  font-size: 18px;
`
const Row = styled.div`
  padding: 0.375rem 0;

  border-bottom: 2px solid
    ${props => hex2rgba(props.theme.color.primary, 0.125)};
`
const FlexRow = styled(Row)`
  display: flex;

  &[data-justify="space-between"] {
    justify-content: space-between;
  }
  &[data-justify="flex-end"] {
    justify-content: flex-end;
  }

  span {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`
const SeparatorRow = styled(Row)`
  border-bottom: 6px solid ${props => props.theme.color.primary};
`
const ValueRow = styled(Row)`
  display: flex;

  &[data-type="secondary"] {
    padding-left: 1rem;
  }
  &[data-type="tertiary"] {
    padding-left: 2rem;
  }
`
const Label = styled.span`
  &[data-bold="true"] {
    font-weight: bold;
  }
`
const Value = styled.span`
  display: inline-block;
  flex-grow: 1;
  margin-left: 0.875rem;
`
const Percent = styled.span`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 0.875rem;

  font-weight: bold;
`

export default function NutritionFacts(props) {
  const { nutritionFacts } = props

  const [{ language }] = useTranslateValue()

  return (
    <>
      <Title>Nutrition Facts</Title>

      {nutritionFacts && (
        <>
          <SeparatorRow>
            <div>{nutritionFacts.servingSize}</div>
          </SeparatorRow>

          <SeparatorRow>
            {language === "en" && <div>Amount per serving</div>}
            {language === "fr" && <div>Quantité par portion</div>}

            <strong>Calories {nutritionFacts.calories}</strong>
          </SeparatorRow>

          {language === "en" && (
            <FlexRow data-justify="flex-end">% Daily Value</FlexRow>
          )}
          {language === "fr" && (
            <FlexRow data-justify="flex-end">% Valeur quotidienne</FlexRow>
          )}

          {nutritionFacts.values.map((value, valueIndex) => (
            <ValueRow key={valueIndex} data-type={value.type}>
              <Label
                dangerouslySetInnerHTML={{ __html: value.label }}
                data-bold={value.type === "primary"}
              />

              <Value dangerouslySetInnerHTML={{ __html: value.value }} />

              <Percent dangerouslySetInnerHTML={{ __html: value.percent }} />
            </ValueRow>
          ))}
        </>
      )}
    </>
  )
}
