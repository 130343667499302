import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"

const Wrapper = styled.header`
  position: relative;
  padding: 1rem 3rem 0.9rem;
`
const Tagline = styled.div`
  text-align: center;
  flex-grow: 1;

  font-family: ${props => props.theme.font.heading};
  font-size: 1.325em;
`
const CloseButton = styled.button`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);

  background-color: transparent;
  color: inherit;
  font-size: 1.9em;
  border: none;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

export default function Header(props) {
  const themeContext = useContext(ThemeContext)

  const { data, isActive, closePanel } = props

  const headerStyles = {
    backgroundColor:
      isActive && data ? data.backgroundColor : themeContext.color.mediumBg,
    color: isActive && data ? data.textColor : themeContext.color.primary,
  }

  return (
    <Wrapper style={headerStyles}>
      {data && data.tagline && <Tagline>{data.tagline}</Tagline>}
      <CloseButton onClick={closePanel}>&times;</CloseButton>
    </Wrapper>
  )
}
