import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import Header from "./Header"
import Content from "./Content"

const Wrapper = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  flex-basis: 0;

  @media (min-width: 1025px) {
    order: 100;
  }

  &[data-active="true"] {
    margin-top: 0.5rem;
    padding: 1rem;
    height: auto;
    flex-basis: 100%;

    overflow: visible;

    @media (max-width: 640px) {
      padding: 0;
    }
  }
`
const Panel = styled.div`
  margin: 0 1rem;

  box-shadow: 0 2px 8px 4px rgba(0, 2, 8, 0.1);
  border-radius: 30px;
  overflow: hidden;

  @media (max-width: 640px) {
    margin: 0;
  }
`

export default function Detail(props) {
  const {
    data,
    activeFlavor,
    setActiveFlavor,
    flavorIndex,
    incomingFlavor,
  } = props

  const isActive = activeFlavor === flavorIndex

  const panelRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data.id) {
        const match = data.id === incomingFlavor
        if (match) {
          setActiveFlavor(flavorIndex)
        }
      }
    }, 400)

    return () => clearTimeout(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isActive) {
      if (panelRef.current.offsetTop > 0) {
        window.scroll({
          top: panelRef.current.offsetTop - 530,
          behavior: "smooth",
        })
      }
    }
  }, [isActive])

  const closePanel = () => {
    setActiveFlavor(0)
  }

  return (
    <Wrapper data-active={isActive}>
      <Panel
        ref={el => {
          panelRef.current = el
        }}
      >
        <Header data={data} isActive={isActive} closePanel={closePanel} />
        <Content data={data} />
      </Panel>
    </Wrapper>
  )
}
